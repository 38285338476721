const itemsAdapter = (data) => {
  return {
    num: data.num,
    text: data.text,
  };
};

export default ({ data }) => {
  return {
    items: data?.items.map(itemsAdapter),
  };
};
